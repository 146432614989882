export default theme => ({
	root: {
		borderTop: '1px solid #dcdcdc',
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		'& > img': { 
			maxWidth: '100px', marginRight: theme.spacing(2)
		},
		'& > .MuiBox-root': { 
			flexShrink: '0',
			flexGrow: '1', width: 'min(500px, 25vw)',
            '& + .MuiBox-root': {
                width: 'auto',
                flexGrow: 0,
                flexShrink: 1,
                display: 'flex',
                flexDirection: 'row',
                '& > .MuiButtonBase-root': {
                    padding: theme.spacing(0, 2),
                    margin: theme.spacing(2, 0),
                    '&:first-child': {
                        borderRight: '1px solid #dcdcdc'
                    }
                }
            }
		},
		'& > .MuiButtonBase-root': { 
			// maxWidth: '120px', 
			margin: theme.spacing(2), 
			// marginRight: theme.spacing(2),
			'&:last-child': { marginRight: 0 },
			'& > img': {
				maxWidth: '100%'
			}
		},
		[theme.breakpoints.down('md')]: {
			flexWrap: 'wrap', justifyContent: 'center'
		}
	}
})